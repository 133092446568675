import {loader} from "../utils/Loading";
import {PaymentBackend} from "./PaymentBackend";

export class CentrobillWorkflow extends PaymentBackend {

  constructor(params) {
    super();
    
    this.formId = params.formId || "centrobill-form";
    this.$container = $(`#${this.formId}-container`);

    this._formSubmitionEnabled = false;
    this._submitButtonEnabled = false;

    if (this.$container.length > 0) {
      this.$form = $(`form#${this.formId}`);
      this.form = this.$form[0];
      this.$submitButton = this.$form.find("button[data-role=submit-button]");
      this.transactionStatus = params.transactionStatus;
      this.initEvents();
      this.initClientFields();
      this.enableSubmitButton(true);


    }
  }
  
  enableSubmitButton(value) {
    this._submitButtonEnabled = value;
    if (value) {
      this.$submitButton.removeAttr("disabled");
      this.$submitButton.removeAttr("disabled");
    } else {
      this.$submitButton.attr("disabled", "disabled");
      this.$submitButton.addClass("disabled");      
    }
  }

  checkValidity() {
    let isFormValid;
    
    isFormValid = this.form.reportValidity();
    
    window.document.querySelectorAll("input[data-hostedfields]").forEach((input) => {
      
      if (input.dataset.pattern) {
        const fieldValid =new RegExp(input.dataset.pattern).test(input.value || "");
        if (!fieldValid) {
          isFormValid = false;
          $(input).setFieldError([input.dataset.error]);
        }
      }
      
    });

    const expMonthField = window.document.getElementById("id_exp_month");
    const expYearField = window.document.getElementById("id_exp_year");
    if (expMonthField.value && expYearField.value) {

      const expirationDate = new Date(2000 + parseInt(expYearField.value), expMonthField.value, 1); 
      if (expirationDate <= new Date()) {
        isFormValid = false;
        this.displayFormErrors([{ message: expYearField.dataset.expiredError }]);
        
      }
    }
    
    

    return isFormValid;
  }

  initEvents() {

    this.$submitButton.click( (event) => {
      event.preventDefault();
      
      if (!this._submitButtonEnabled) return;
      this.enableSubmitButton(false);
      loader.show(`${this.formId}-submit`);
      this.$form.cleanFormErrors();

      const isFormValid = this.checkValidity();
      if (isFormValid) {
        this.tokenize().then((token) => {
          this.form.elements["token"].value = token;
          
          this._formSubmitionEnabled = true;
          this.$form.submit();
          
        }).catch((message) => {
          this.displayFormErrors([{ message }]);
          this.onFormResponseError();
        });
        
      } else {
        this.onFormResponseError();
      }
    });

    this.$form.handleLightAjaxSubmit({
      submitAllowed: (callback) => {
        if (this._formSubmitionEnabled) {
          this._formSubmitionEnabled = false;
          callback(true);
        }
      },
      onResponse: (success, data) => {

        loader.hide(`${this.formId}-submit`);

        if (success) {          
          loader.show(`${this.formId}-transaction-finalize`);

          this.transactionStatus.addTransaction(data.transaction);

          if (!this.transactionStatus.isStarted) {
            this.transactionStatus.start().then((response) => {
              const url = data["url"] || response["url"];

              if (url) {
                window.location.href = url;
              }
            });
          }

        } else {
          this.onFormResponseError();
        }
      }
    });

    
  }

  


  tokenize() {

      var data = {
        cardHolder: $('#id_firstname').val() + ' ' + $('#id_lastname').val(),
        zip: $('#id_zipcode').val(),
        number: $('#id_card_number').val(),
        expirationMonth: $('#id_exp_month').val(),
        expirationYear: $('#id_exp_year').val(),
        cvv: $('#id_cvv').val(),
      };
      
      return new Promise((resolve, reject) => {
       
        const options = {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            // 'X-Request-ID': 'sd',            
            'x-requested-with': 'XMLHttpRequest'
          },
          body: JSON.stringify(data)
        };
        
        fetch('https://api.centrobill.com/tokenize', options)          
          .then(res => {
            if (res.status === 201) {
              res.json().then(({token, expireAt}) => {
                resolve(token);
              }).catch(reject)
            } else {
              reject(`Tokenization returned ${res.status}`)
            }
             
          })          
          .catch(err => reject(err));

      });
  }

  displayFormErrors(errors) {
    const errorMessages = [];

    for (let i = 0; i < errors.length; i++) {
      const error = errors[i];
      errorMessages.push(error["message"]);
    }

    this.$form.setFormErrors({
      messages: errorMessages
    });
  }

  onFormResponseError() {
    loader.hide(`${this.formId}-submit`);
    loader.hide(`${this.formId}-transaction-finalize`);
    this.enableSubmitButton(true);

    if (this.transactionStatus.isStarted) {
      this.transactionStatus.stop();
      this.transactionStatus.clearTransactions();
    }
  }

}

